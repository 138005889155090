import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { PopularNews,News } from './components';
import { Typography } from '@mui/material';

const BlogNewsroom = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Container>
        <PopularNews />
      </Container>
      {/* <Container>
        <Box>
          <Typography variant={'h3'}>
            Meet the Team
          </Typography>
          <Typography variant={'h5'}>
            Claims Manager : Sharon Petzer
          </Typography>
          <Typography variant={'h5'}>
            Contact: 021 022 66913
          </Typography>
          <Typography variant={'h5'}>
          Hours: Between 8am and 5pm Monday to Friday
          </Typography>
          <Typography variant={'h5'}>
          Email:claims@fglife.co.nz
          </Typography>
        </Box>
      </Container> */}

    </Main>
  );
};

export default BlogNewsroom;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

const mock = [
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/ClaimProcess1.jpeg',
    description:"At FG Life we understand that having to submit a claim can be daunting. This is why we offer our clients end-to-end claim assistance.In order for us to effectively ease the claims process we have implemented a hands on internal claims procedure. As part of our service to you as our clients, our dedicated claims manager endeavours to assist and guide you in your claim process every step of the way. We are there for you from start to finish, providing continuity, expertise, and advocating on your behalf with the providers.We'll not only save you time, but also strive to make it as simple and hassle-free as possible for you.",
    // description:
    //   "FG Life understands that a claim incident can be daunting which is why we have implemented an internal in-depth claim process in order for us to effectively offer end-to-end assistance in your claim process. As part of our service to you as our clients, our dedicated claims team endeavors to assist and guide you in your claim process every step of the way. We handle your claim from start to finish, providing continuity, expertise, and advocating on your behalf with the providers. We'll not only save you time, but strive to make it as simple and hassle-free as possible for you.",
    desc: '',
    title: 'FG Life Insurance Claims',
    tags: [],
    author: {
      name: 'Clara Bertoletti',
      avatar: 'https://assets.maccarianagency.com/avatars/img2.jpg',
    },
    date: '04 Aug',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/MakeClaim.jpeg',
    description:"A claim notification can be done by contacting our friendly claims manager. This can be done either by calling or via email. In both instances, our friendly claims manager will discuss the claim with you and advise you of the next steps.",
      // description:
    //   'Claim incident notification can be done either verbally by contacting our claims team over the phone in which case our friendly claims handler will discuss the claim incident with you and advise you of the next steps or alternatively a claim incident notification can be done via email to the claims team. A friendly claims team handler will then contact you and advise you of the next steps.',
    desc: '',
    title: 'Claim Notification',
    tags: ['Manager: Atika Dogra','Contact: +64 272 51796 6','Email: atika@fglife.co.nz'],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/FileClaim.jpeg',
    description:"A claim form will be sent to you with concise instructions and relevant requirements for completion as well as contact details for our claims manager should you have any queries or require assistance in completing the claim form. Once you have returned the completed claim form back to our dedicated claims manager, we will submit your claim to the relevant insurance provider. We are here for you. Should you prefer to submit a claim through to the insurance provider directly,  we can provide all the necessary information you may need. ",
    // description:
    //   'A claim form will be sent to you with concise instructions and relevant requirements for completion as well as contact details for our claims team should you have any queries or require assistance in completing the claim form. Once you have returned the completed claim form back to our dedicated claims team, we will submit your claim to the relevant insurance provider. You have the option of submitting a claim directly through to the insurance provider skipping us the broker – FG Life. In this instance unless you or the insurance provider notifies our claims team of this action, there is no way for our claims team to follow up on nor will we be aware that a claim exists. Should we however be made aware, then the claims process will continue as stipulated in this document.',
    desc: '',
    title: 'Completion of Claim Form',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/ClaimSettlement.jpeg',
    description:
      'We will always enquire as to your acceptance of the claim outcome, and we will advocate on your behalf with the insurance providers for the best possible outcome.',
    desc: '',
    title: 'Claim Outcome',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/FollowUp.jpeg',
    description:
      'Claim follow ups are actioned throughout a particular claim’s lifespan. Follow ups will always include claim acknowledgement, claim reference number and claim status. Requirement requests, status update requests and or general updates are attended to as promptly as possible.',
    desc: '',
    title: 'Claim Follow Up',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
];

const PopularNews = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          data-aos={'fade-up'}
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Claims Process
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              borderRadius={0}
              boxShadow={0}
              display={'flex'}
              flexDirection={{
                xs: 'column',
                md: i % 2 === 0 ? 'row-reverse' : 'row',
              }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: '50%' },
                }}
              >
                <Box
                  component={'img'}
                  loading="lazy"
                  height={1}
                  width={1}
                  src={item.image}
                  alt="..."
                  sx={{
                    objectFit: 'cover',
                    maxHeight: 360,
                    borderRadius: 2,
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  paddingX: { xs: 1, sm: 2, md: 4 },
                  paddingY: { xs: 2, sm: 4 },
                  width: { xs: 1, md: '50%' },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  {item.tags.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      component="a"
                      href=""
                      clickable
                      size={'small'}
                      color={'primary'}
                      sx={{ marginBottom: 1, marginRight: 1 }}
                    />
                  ))}
                </Box>
                <Typography
                  variant={'h6'}
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {item.title}
                </Typography>
                {/* <Box marginY={1}>
                  <Typography
                    variant={'caption'}
                    color={'text.secondary'}
                    component={'i'}
                  >
                    {item.author.name} - {item.date}
                  </Typography>
                </Box> */}
                <Typography color="text.secondary">
                  {item.description}
                  <br />
                  <br />
                  {item.desc}
                </Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PopularNews;
